import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Container = Styled.div`
  margin-top: 86px;
  p {
    margin-top: 35px;
  }

  @media all and (min-width: 992px) {
    p {
      white-space: pre-wrap;
    }
    margin-top: 60px;
    padding: 50px 75px;
  }
}
`

const StyledImg = Styled(Img)`
  width: 100%;
  height: 100%;

  @media all and (min-width: 992px) {
    width: 60vh;
    height: 60vh;
  }
`

const title = "The process"

const Process = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "process-chart.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout activeMenu={title}>
      <SEO title="The Process" />
      <Container>
        <StyledImg fluid={data.placeholderImage.childImageSharp.fluid} />
        <p
          dangerouslySetInnerHTML={{
            __html:
              "Through a continuous and dramatic expansion of ideas,\nThe Process produces a purpose greater than the sum of its parts.<br /><span style='color: #ffcc99'>Visible Brand Actions™</span>",
          }}
        />
      </Container>
    </Layout>
  )
}

export default Process
